<template>
  <div class="container mx-auto px-4 sm:px-8 w-full">
    <div class="py-8 w-full">
      <div class="flex flex-row mb-1 sm:mb-0 justify-between w-full">
        <div class="flex items-center">
          <!-- Ensure the svg is displayed inline with the text -->
          <svg
            viewBox="0 0 24 24"
            class="icon-play"
            fill="currentColor"
            style="
              width: 24px;
              height: 24px;
              color: #012169;
              vertical-align: middle;
            "
          >
            <path d="M8 5v14l11-7z" />
          </svg>
          <h2
            class="text-2xl leading-tight"
            style="
              display: inline;
              margin-left: 8px;
              font-weight: 600;
              color: #012169;
            "
          >
            Video Manager
          </h2>
        </div>
        <div class="flex justify-end space-x-3">
          <div class="relative">
            <select
              v-model="selectedSortColumn"
              @change="sortTable"
              class="bg-white border border-gray-300 text-gray-700 py-2 px-2 pr-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            >
              <option disabled value="">Sort By...</option>
              <option value="ID">Sort by ID</option>
              <option value="Title">Sort by Title</option>
              <option value="Ensemble">Sort by Ensemble</option>
              <option value="Channel">Sort by Channel</option>
              <option value="Event/Status">Sort by Event/Status</option>
            </select>
          </div>
          <div class="relative">
            <select
              v-model="resultsPerPage"
              @change="updatePage(1)"
              class="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-2 px-2 pr-4 rounded"
            >
              <option
                v-for="option in [5, 10, 25, 50, 100]"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <PaginationComponent
            :totalPages="totalPages"
            :currentPage="currentPage"
            @pageChanged="updatePage"
            class="py-2"
          />
        </div>
      </div>
      <div class="py-4">
        <div>
          <table class="w-full min-w-full leading-normal">
            <thead>
              <tr>
                <th
                  class="px-5 py-3 border-b-2 border-grey-lightest text-left text-xs font-semibold text-blue-900 uppercase tracking-wider"
                >
                  ID
                </th>
                <th
                  class="px-5 py-3 border-b-2 border-grey-lightest text-left text-xs font-semibold text-grey-dark uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  class="px-5 py-3 border-b-2 border-grey-lightest text-left text-xs font-semibold text-grey-dark uppercase tracking-wider"
                >
                  Actions
                </th>
                <th
                  class="px-5 py-3 border-b-2 border-grey-lightest text-left text-xs font-semibold text-blue-900 uppercase tracking-wider"
                >
                  Ensemble/Location
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="video in filteredVideos"
                :key="`group-${video.ID}`"
              >
                <tr class="main-video-row">
                  <td class="px-5 py-5 border-b border-grey-light">
                    {{ video.id || "N/A" }}
                  </td>
                  <td class="px-5 py-5 border-b border-grey-light">
                    <div v-if="video.isEditing">
                    <input v-model="video.editedTitle" class="px-2 py-1 border rounded">
                    </div>
                    <div v-else>
                      {{ video.title || "Untitled" }}
                    </div>
                  </td>
                  <td class="px-5 py-5 border-b border-grey-light">
                    <button @click="toggleEdit(video)" class="px-3 py-1 text-white bg-blue-500 rounded">
                      {{ video.isEditing ? 'Close' : 'Edit' }}
                    </button>
                    <button v-if="video.isEditing && (video.title !== video.editedTitle || video.description !== video.editedDescription)" @click="saveTitle(video)" class="ml-2 px-3 py-1 text-white bg-green-500 rounded">Save</button>
                  </td>
                  <td class="px-5 py-5 border-b border-grey-light">
                    {{ video.ensemble_name || "Unknown Ensemble" }}
                  </td>
                </tr>
                <tr v-if="video.isEditing" class="description-edit-row">
                  <td colspan="5" class="px-5 py-5 border-b border-grey-light">
                    <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
                    <textarea v-model="video.editedDescription" class="w-full px-2 py-1 border rounded" placeholder="Edit description" rows="2"></textarea>
                  </td>
                </tr>
                <tr class="extra-row-component">
                  <td colspan="5" class="px-5 py-5 border-b border-grey-light">
                    <ExtraRowComponent
                      :extraData="video.ownVideocomposition?video.ownVideocomposition:[]"
                      :platformData="parsePlatformData(video.platforms)"
                      :videoId="+video.id"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from "/src/components/PaginationComponent.vue";
import ExtraRowComponent from "./ExtraRowComponent.vue";
import axios from 'axios';

export default {
  components: {
    PaginationComponent,
    ExtraRowComponent,
  },
  props: ["tableConfig", "videos"],
  data() {
    return {
      noVideoCount: 0,
      deniedCount: 0,
      publicCount: 0,
      privateCount: 0,
      selectedSortColumn: "",
      resultsPerPage: 5,
      currentPage: 1,
      localVideos: this.videos.map(video => ({
        ...video,
        isEditing: false,
        editedTitle: video.title || '',
      editedDescription: video.description || ''
      })),
    };
  },
  computed: {
    minResult() {
      return (this.currentPage - 1) * this.resultsPerPage + 1;
    },
    maxResult() {
      return Math.min(
        this.currentPage * this.resultsPerPage,
        this.localVideos.length
      );
    },
    totalPages() {
      return Math.ceil(this.localVideos.length / this.resultsPerPage);
    },
    filteredVideos() {
      const start = (this.currentPage - 1) * this.resultsPerPage;
      const end = start + this.resultsPerPage;
      return this.localVideos.slice(start, end);
    },
  },
  methods: {
    loadData(filter) {
      console.log(filter);
      // Implement your logic to load data based on filter
    },
    parsePlatformData(videodata) {
      if (videodata) {
        const pairs = videodata.split("; ");
        const parsedPlatformData = pairs.map((pair) => {
          const [id, platform, videoLink, uploadDate] = pair.split(",");
          return { id, platform, videoLink, uploadDate };
        });
        return parsedPlatformData;
      }
    },
    toggleEdit(video) {
      video.editedDescription = video.description;
      video.editedTitle = video.title;
      video.isEditing = !video.isEditing;
    },
    async saveTitle(video) {
      video.title = video.editedTitle;
      video.description = video.editedDescription;
      video.isEditing = false;
      const payload = {
          video_id: video.id,
          description: video.editedDescription,
          title: video.editedTitle,
        };
        const response = await axios.post(`${axios.defaults.baseURL}video/edit-video-info/`, payload);

    },
    sortTable() {
      if (!this.selectedSortColumn) return;

      const columnPropertyMap = {
        ID: "id",
        Title: "title",
        Ensemble: "ensemble_name",
        Channel: "networkchannel.name",
        "Event/Status": "event.name",
      };

      const sortProperty = columnPropertyMap[this.selectedSortColumn];
      const isNumericSort = sortProperty === "id";

      this.localVideos.sort((a, b) => {
        let aValue = sortProperty.split(".").reduce((o, i) => o[i], a);
        let bValue = sortProperty.split(".").reduce((o, i) => o[i], b);

        if (isNumericSort) {
          aValue = Number(aValue);
          bValue = Number(bValue);
        }

        if (aValue > bValue) return 1;
        if (aValue < bValue) return -1;
        return 0;
      });
    },

    getStatusClass(status) {
      return status === "Approved" ? "status-approved" : "status-denied";
    },
    updatePage(page) {
      this.currentPage = page;
    },
  },
  watch: {
    videos: {
      handler(newVal) {
        this.localVideos = [...newVal];
        console.log(this.localVideos);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.filter-buttons {
  text-align: right;
  margin-bottom: 8px;
}

.filter-button {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 12px;
}

.main-row {
  background-color: #eeeeee;
}
table {
  width: 100%;
}
td {
  border: none;
  text-align: left;
}
.main-video-row {
  background-color: #f5f5f5; /* Light grey background */
}

/* Style for the extra row component */
.extra-row-component td {
  border-bottom: 1px solid #cccccc; /* Light grey line under the whole 'row' */
}

.oval {
  background-color: #eee;
  border-radius: 25%;
  padding: 5px 15px;
  margin-left: 4px;
}

.table-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.status-approved {
  background-color: green;
  color: white;
  border-radius: 12px;
  padding: 0.25rem 0.5rem;
  display: inline-block;
}

.status-denied {
  background-color: red;
  color: white;
  border-radius: 12px;
  padding: 0.25rem 0.5rem;
  display: inline-block;
}

thead tr {
  font-weight: bold;
}

td,
th {
  border: none;
  padding: 8px;
}

tbody tr:hover {
  background-color: #f5f5f5;
}

.results-range {
  font-size: 13.5px;
  text-decoration: underline;
  color: #666666;
  padding: 4px;
  margin-right: 12px;
}

/* Add your existing table and other styles below this line */
</style>
