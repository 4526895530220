<template>
  <div class="container mx-auto flex flex-col items-center pt-36 pb-12 px-4 md:px-0" style="background-color: #212c4f;">
    <div class="w-full md:w-2/3 lg:w-2/2 mx-auto bg-gray-800 p-8 rounded-lg shadow-md">
      <h1 class="text-white text-2xl mb-4">Billing Information</h1>

      <!-- Amount Due Section -->
      <div class="mb-4">
        <h3 class="text-white text-xl">Amount Due: {{ funeralForm.amountDue }}</h3>
      </div>

      <!-- Credit Card Form -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

         <!-- Name on Card -->
         <div class="form-group">
          <label for="cardName" class="text-white">Name on Card *</label>
          <input
            v-model="cc.name"
            @blur="validateCardName"
            id="cardName"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': cardErrors.cardName}"
            placeholder="Full Name"
            autocomplete="cc-name"
          />
          <p v-if="cardErrors.cardName" class="text-red-500 text-sm">{{ cardErrors.cardName }}</p>
        </div>

        <!-- Card Number -->
        <div class="form-group">
          <label for="cardNumber" class="text-white">Card Number *</label>
          <div class="relative">
            <input
              v-model="cc.number"
              @input="formatCardNumber"
              @blur="validateCardNumber"
              @keyup="autoTab('cardExpiry')"
              id="cardNumber"
              class="w-full p-3 border rounded-lg bg-gray-700 text-white"
              :class="{'border-red-500': cardErrors.cardNumber}"
              placeholder="#### #### #### ####"
              autocomplete="cc-number"
            />
            <img
              v-if="cc.creditcardtype"
              :src="`/img/cc/sm/${cc.creditcardtype}.png`"
              alt="Card Type"
              class="absolute right-2 top-3 h-6"
            />
          </div>
          <p v-if="cardErrors.cardNumber" class="text-red-500 text-sm">{{ cardErrors.cardNumber }}</p>
        </div>

        <!-- Expiration Date -->
        <div class="form-group">
          <label for="cardExpiry" class="text-white">Expiration *</label>
          <input
            v-model="cc.expiry"
            @input="formatCardExpiry"
            @blur="validateCardExpiry"
            @keyup="autoTab('cardCvc')"
            id="cardExpiry"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': cardErrors.cardExpiry}"
            placeholder="MM / YYYY"
            autocomplete="cc-exp"
          />
          <p v-if="cardErrors.cardExpiry" class="text-red-500 text-sm">{{ cardErrors.cardExpiry }}</p>
        </div>

        <!-- CVC -->
        <div class="form-group">
          <label for="cardCvc" class="text-white">CVC Code *</label>
          <input
            v-model="cc.cvc"
            @input="formatCVC"
            @blur="validateCardCVC"
            id="cardCvc"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': cardErrors.cardCvc}"
            placeholder="CVC"
            autocomplete="cc-csc"
          />
          <p v-if="cardErrors.cardCvc" class="text-red-500 text-sm">{{ cardErrors.cardCvc }}</p>
        </div>
      </div>

      <!-- Billing Address Checkbox -->
      <div class="form-group mt-4">
        <label class="text-white">
          <input type="checkbox" v-model="sameAsLicenseeAddress" /> Billing address same as Licensee Address
        </label>
      </div>

      <!-- Billing Address Fields -->
      <div v-if="!sameAsLicenseeAddress" class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div class="form-group">
          <label for="billingAddress" class="text-white">Street Address *</label>
          <input
            v-model="billingAddress.street"
            @input="fetchAddressSuggestions"
            @blur="validateBillingAddress"
            id="billingAddress"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.street}"
            placeholder="Street Address"
            autocomplete="street-address"
          />
          <ul v-if="addressSuggestions.length" class="absolute z-10 bg-white mt-1 rounded-md shadow-lg">
            <li
              v-for="suggestion in addressSuggestions"
              :key="suggestion.id"
              @click="selectAddressSuggestion(suggestion)"
              class="cursor-pointer p-3 hover:bg-gray-100"
            >
              {{ suggestion.place_name }}
            </li>
          </ul>
          <p v-if="billingErrors.street" class="text-red-500 text-sm">{{ billingErrors.street }}</p>
        </div>

        <!-- City -->
        <div class="form-group">
          <label for="billingCity" class="text-white">City *</label>
          <input
            v-model="billingAddress.city"
            id="billingCity"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.city}"
            placeholder="City"
            autocomplete="address-level2"
          />
          <p v-if="billingErrors.city" class="text-red-500 text-sm">{{ billingErrors.city }}</p>
        </div>

        <!-- State -->
        <div class="form-group">
          <label for="billingState" class="text-white">State *</label>
          <input
            v-model="billingAddress.state"
            id="billingState"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.state}"
            placeholder="State"
            autocomplete="address-level1"
          />
          <p v-if="billingErrors.state" class="text-red-500 text-sm">{{ billingErrors.state }}</p>
        </div>

        <!-- Zip Code -->
        <div class="form-group">
          <label for="billingZip" class="text-white">Zip Code *</label>
          <input
            v-model="billingAddress.zip"
            id="billingZip"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.zip}"
            placeholder="Zip Code"
            autocomplete="postal-code"
          />
          <p v-if="billingErrors.zip" class="text-red-500 text-sm">{{ billingErrors.zip }}</p>
        </div>

        <!-- Country -->
        <div class="form-group">
          <label for="billingCountry" class="text-white">Country *</label>
          <input
            v-model="billingAddress.country"
            id="billingCountry"
            class="w-full p-3 border rounded-lg bg-gray-700 text-white"
            :class="{'border-red-500': billingErrors.country}"
            placeholder="Country"
            autocomplete="country-name"
          />
          <p v-if="billingErrors.country" class="text-red-500 text-sm">{{ billingErrors.country }}</p>
        </div>
      </div>
      <div class="mt-6 p-4 border rounded-lg shadow-md bg-gray-50">
        <h3 class="text-lg font-semibold text-gray-800">Payment Summary</h3>
        <div class="mt-4 space-y-2">
          <div class="flex justify-between text-gray-700">
              <span>LiSynce Fee:</span>
              <span>{{ fees.liSynceFee }}</span>
          </div>
          <div class="flex justify-between text-gray-700">
              <span>Transaction Fee:</span>
              <span>{{ fees.transactionFee }}</span>
          </div>
          <div class="flex justify-between text-gray-900 font-bold">
              <span>Total Amount Due:</span>
              <span>{{ fees.totalAmount }}</span>
          </div>
        </div>
      </div>

      <p class="text-sm text-gray-500 mt-2">
        * Transaction fee is included in the total amount for this agreement.
      </p>

      <!-- Submit Button -->
      <button
        class="mt-4 bg-green-600 text-white py-3 px-6 rounded-lg w-full"
        @click="submitBilling"
        :disabled="isLoading"
      >
        <div v-if="isLoading">
          <!-- Loader Icon or Animation -->
          <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
        </div>
        <div v-else>Submit Payment</div>
      </button>
    </div>
  </div>
</template>
<script>
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import axios from 'axios';

export default {
  name: 'CreditCardForm',
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      userId: 0,
      cc: {
        name: '',
        number: '',
        expiry: '',
        cvc: '',
        creditcardtype: null
      },
      billingAddress: {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      addressSuggestions: [],
      sameAsLicenseeAddress: false,
      cardErrors: {
        cardName: null,
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null,
      },
      billingErrors: {
        street: null,
        city: null,
        state: null,
        zip: null,
        country: null,
      },
      funeralForm: {},
      isLoading: false,
      fees: {
        liSynceFee: "$0.00",
        transactionFee: "$0.00",
        totalAmount: "$0.00",
      },
    };
  },
  mounted() {
    this.funeralForm = this.$store.getters.getFuneralForm;

    // Check if funeralForm contains a licensee billing address string
    if (this.funeralForm && this.funeralForm.licenseeBillingAddress) {
      // Call the selectAddressSuggestion function to parse and fill the billing address fields
      this.selectAddressSuggestion({ place_name: this.funeralForm.licenseeBillingAddress });

      // Automatically check the "Billing address same as Licensee Address" checkbox
      this.sameAsLicenseeAddress = true;
    } else {
      // If no billing address in funeralForm, allow manual input
      this.sameAsLicenseeAddress = false;
    }
    if(this.funeralForm && this.funeralForm.contactFirstName && this.funeralForm.contactLastName) {
      this.cc.name = this.funeralForm.contactFirstName + ' ' + this.funeralForm.contactLastName;
    }

    if(this.funeralForm && this.funeralForm.isFAMICMember && this.funeralForm.numLocations) {
      this.calculateAmount();
    }
    this.init()
  },
  watch: {
    sameAsLicenseeAddress(newVal) {
      if (newVal) {
        // If checkbox is checked, fill in the billing address from funeralForm
        if (this.funeralForm && this.funeralForm.licenseeBillingAddress) {
          this.selectAddressSuggestion({ place_name: this.funeralForm.licenseeBillingAddress });
        }
        console.log(this.billingAddress)
      } else {
        // Clear billing address fields if checkbox is unchecked
        this.billingAddress.street = '';
        this.billingAddress.city = '';
        this.billingAddress.state = '';
        this.billingAddress.zip = '';
        this.billingAddress.country = '';
      }
    },
    'funeralForm.numLocations': 'calculateAmount',
    'funeralForm.isFAMICMember': 'calculateAmount',
  },
  methods: {
    init() {
      axios.post(axios.defaults.baseURL + 'account/lisynce/getuserid', {email: this.funeralForm.contactEmail})
      .then(response => {
        //download the pdf agreement
        this.userId = response.data.user_id;
      })
      .catch(error => {
        console.error('Error retrieving userId:', error);
      });
    },
    calculateAmount() {
      console.log('from cc' ,  this.funeralForm.isFAMICMember);
      console.log('from cc' ,  (this.funeralForm.isFAMICMember == true || this.funeralForm.isFAMICMember == 'true'));
      const feePerLocation = (this.funeralForm.isFAMICMember == true || this.funeralForm.isFAMICMember == 'true') ? 66 : 74;
      const locations = this.funeralForm.numLocations || 1;
      const totalFee = feePerLocation * locations;
      const transactionFee = parseFloat((totalFee * 0.04).toFixed(2)); // 4% processing fee
      const totalAmount = parseFloat((totalFee + transactionFee).toFixed(2));

      this.fees = {
          liSynceFee: `$${totalFee.toFixed(2)}`,
          transactionFee: `$${transactionFee.toFixed(2)}`,
          totalAmount: `$${totalAmount.toFixed(2)}`,
      };
    },
    // Format card number as XXXX XXXX XXXX XXXX
    formatCardNumber(event) {
      const value = event.target.value.replace(/\D/g, '');
      if (/^3[47]/.test(value)) {
        // American Express pattern: 4 digits, 6 digits, 5 digits
        this.cc.number = value
          .replace(/(\d{4})(\d{0,6})(\d{0,5})/, (match, p1, p2, p3) => {
            return [p1, p2, p3].filter(Boolean).join(' ');
          });
      } else {
        this.cc.number = value
          .replace(/(\d{4})(?=\d)/g, '$1 ')
          .trim();
      }
      this.setCardType();
    },

    // Format expiration date as MM/YY or MM/YYYY
    formatCardExpiry(event) {
      const value = event.target.value.replace(/\D/g, '').slice(0, 6); // Only digits, up to 6 (MMYYYY)
      if (value.length > 2) {
          this.cc.expiry = value.slice(0, 2) + '/' + (value.length > 4 ? value.slice(2, 6) : value.slice(2, 4));
      } else {
          this.cc.expiry = value;
      }
    },

    // Format CVC: Limit input to 3 digits (or 4 for Amex)
    formatCVC(event) {
      this.cc.cvc = event.target.value.replace(/\D/g, '').slice(0, 4); // Only digits, limit to 3 or 4
    },

    // Set the card type based on the number entered
    setCardType() {
      // const number = this.cc.number.replace(/\s/g, ''); // Remove spaces
      // if (/^4/.test(number)) {
      //   this.cc.creditcardtype = 'Visa';
      // } else if (/^5[1-5]/.test(number)) {
      //   this.cc.creditcardtype = 'MasterCard';
      // } else {
      //   this.cc.creditcardtype = null;
      // }
      const cleanNumber = this.cc.number.replace(/\D/g, ''); // Remove all non-digit characters
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'Visa';
      } else if (/^5[1-5][0-9]{14}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'MasterCard';
      } else if (/^3[47][0-9]{13}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'American Express';
      } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'Discover';
      } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cleanNumber)) { 
        this.cc.creditcardtype = 'Diners Club';
      } else if (/^(?:352[89]|35[3-8][0-9])[0-9]{12}$/.test(cleanNumber)) {
        this.cc.creditcardtype = 'JCB';
      } else {
        this.cc.creditcardtype = null;
      }
      console.log(this.cc.creditcardtype);
    },

    // Luhn Algorithm to validate credit card number
    luhnCheck(number) {
      let sum = 0;
      let shouldDouble = false;
      for (let i = number.length - 1; i >= 0; i--) {
        let digit = parseInt(number.charAt(i));
        if (shouldDouble) {
          digit *= 2;
          if (digit > 9) digit -= 9;
        }
        sum += digit;
        shouldDouble = !shouldDouble;
      }
      return sum % 10 === 0;
    },

    // Validate card number
    validateCardNumber() {
      const number = this.cc.number.replace(/\s/g, '');
      this.cardErrors.cardNumber = this.luhnCheck(number) && (number.length == 16 || number.length == 15 || number.length == 14) ? null : 'Invalid Credit Card Number';
    },

    // Validate expiration date
    validateCardExpiry() {
      const [month, year] = this.cc.expiry.split('/');
      const currentYear = new Date().getFullYear(); // Full four-digit year
      const currentYearTwoDigit = currentYear % 100; // Last two digits of the current year
      const currentMonth = new Date().getMonth() + 1; // Get current month

      let fullYear; // To store the four-digit year based on input

      if (!month || !year || month < 1 || month > 12) {
          this.cardErrors.cardExpiry = 'Invalid Expiration Date';
          return;
      }

      if (year.length === 2) {
          // User entered two-digit year
          fullYear = 2000 + parseInt(year); // Convert to four-digit year
      } else if (year.length === 4) {
          // User entered four-digit year
          fullYear = parseInt(year);
      } else {
          this.cardErrors.cardExpiry = 'Invalid Expiration Date';
          return;
      }

      if (fullYear < currentYear || (fullYear === currentYear && month < currentMonth)) {
          this.cardErrors.cardExpiry = 'Expiration date has passed';
      } else {
          this.cardErrors.cardExpiry = null;
      }
    },

    // Validate CVC (3 digits for Visa/MasterCard, 4 for Amex)
    validateCardCVC() {
      const value = this.cc.cvc;
      const validLength = this.cc.creditcardtype === 'American Express' ? 4 : 3;
      this.cardErrors.cardCvc = value.length === validLength ? null : 'Invalid CVC';
    },

    // Auto tabbing logic
    autoTab(nextFieldId) {
      if (nextFieldId === 'cardExpiry') {
        this.validateCardNumber()
        if (!this.cardErrors.cardNumber) {
          const cardNumberField = document.getElementById('cardNumber');
          if (cardNumberField && this.cc.number.length >= 19) {
            document.getElementById(nextFieldId).focus();
          }
        }
      }
      if (nextFieldId === 'cardCvc') {
        this.validateCardExpiry()
        if (!this.cardErrors.cardExpiry && nextFieldId === 'cardCvc') {
          const expiryField = document.getElementById('cardExpiry');
          if (expiryField && (this.cc.expiry.length == 7 || this.cc.expiry.length == 5)) {
            document.getElementById(nextFieldId).focus();
          }
        }
      }
    },

    // Fetch address suggestions using Mapbox API
    fetchAddressSuggestions() {
      if (!this.billingAddress.street) {
        this.addressSuggestions = [];
        return;
      }

      const API_URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(this.billingAddress.street)}.json?access_token=pk.eyJ1IjoiYnJ5YW4tcG90dHMiLCJhIjoiY2xuZWh6dnk1MGNyNjJvcGVjbzFzeGtpZCJ9.h1ubFAo_rM9sj59jy-OeCw`;

      axios.get(API_URL)
        .then(response => {
          this.addressSuggestions = response.data.features;
        })
        .catch(error => {
          console.error('Error fetching address suggestions:', error);
        });
    },

    //populate all fields
    selectAddressSuggestion(suggestion) {
      const pattern = /^([\w\s\.#\-]+),\s*([\w\s]+),\s*([A-Za-z\s]+)\s+(\d{5}),\s*([A-Za-z\s]+)$/;
      const match = suggestion.place_name.match(pattern);

      if (match) {
        this.billingAddress.street = match[1];  // street
        this.billingAddress.city = match[2];    // city
        this.billingAddress.state = match[3];   // state
        this.billingAddress.zip = match[4];     // zip
        this.billingAddress.country = match[5]; // country
      }

      this.addressSuggestions = [];
    },

    // Submit Billing Information
    submitBilling() {
      this.validateCardNumber();
      this.validateCardExpiry();
      this.validateCardCVC();
      if (this.cardErrors.cardNumber || this.cardErrors.cardExpiry || this.cardErrors.cardCvc || !this.cc.number || !this.cc.expiry || !this.cc.cvc || !this.cc.name) {
        alert('Please fill in all required fields.');
        return;
      }
      this.isLoading = true;

       // Prepare data to send
      const billingData = {
        cardNumber: this.cc.number.replace(/\s/g, ''), // Remove spaces
        expMonth: this.cc.expiry.split('/')[0],       // Extract month
        expYear: this.cc.expiry.split('/')[1],        // Extract year
        cardCode: this.cc.cvc,
        name: this.cc.name,
        email: this.funeralForm.contactEmail,         // Email from form
        address: this.billingAddress.street,
        city: this.billingAddress.city,
        state: this.billingAddress.state,
        zip: this.billingAddress.zip,
        country: this.billingAddress.country,
        cardType: this.cc.creditcardtype,
        orgId: this.funeralForm.orgId,
        user_id: this.userId,
        isFAMICMember: (this.funeralForm.isFAMICMember == false || this.funeralForm.isFAMICMember == 'false') ? 0 : 1,
        numLocations: this.funeralForm.numLocations
      };

      axios.post(`${axios.defaults.baseURL}lisyncepayment/createcustomerprofile`, { billingData: billingData }
      )
      .then(response => {
        if (response.data.status === 'success') {
          console.log('Response:', response.data);
          axios.post(`${axios.defaults.baseURL}account/lisynce/registration_status`, {
            user_id: this.userId,
            status: "PAYMENT_COMPLETE",
          })
          .then(registrationResponse => {
            this.isLoading = false;
            console.log('Registration Status Updated:', registrationResponse.data);
            localStorage.setItem("token", 'jhgfaloihugLWIEUY7O98475T3P9Q4503J$^*hhkugigihjh;LKJ');
            localStorage.setItem("organization_ids", JSON.stringify([this.funeralForm.orgId]));
            localStorage.setItem("userId", this.userId);
            localStorage.setItem("isFuneralHome", 1);
            localStorage.setItem("name", this.funeralForm.contactFirstName + ' ' + this.funeralForm.contactLastName);
            this.$router.push("/").then(() => {
              location.reload();
            });
          })
          .catch(registrationError => {
            console.error('Error updating registration status:', registrationError);
            this.isLoading = false;
            alert('Error updating payment status.');
          });
        } else {
          this.isLoading = false;
          alert('Error: ' + response.data.message);
        }
      })
      .catch(error => {
        this.isLoading = false;
        console.error('Error submitting billing:', error);
        alert('Error processing payment.');
      });
    }
  },
  validations() {
    return {
      cc: {
        name: { required },
        number: { required },
        expiry: { required },
        cvc: { required },
      },
      billingAddress: {
        street: { required },
        city: { required },
        state: { required },
        zip: { required },
        country: { required },
      },
    };
  },
};
</script>

<style scoped>
/* .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  margin: 0 auto;
} */

input {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

input.border-red-500 {
  border-color: red !important;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #4caf50;
}
</style>
