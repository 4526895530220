import { events } from '../events';
import LoaderOverlay from '../../components/LoaderOverlay.vue';

const Loader = {
    install(app) {
      if (this.installed) {
        return;
      }
  
      this.installed = true;
  
      app.component('LoaderOverlay', LoaderOverlay);
  
      const updateLoading = (params) => {
        events.emit('updateloading', params);
      };
  
      const isLoading = () => {
        return events.loading > 0;
      };
  
      app.config.globalProperties.$updateLoading = updateLoading;
      app.config.globalProperties.$isLoading = isLoading;
    },
  };
  
  export default Loader;