<template>
  <div class="container p-4 bg-white">
    <VideoTableComponent
      :tableConfig="tableConfig"
      :videos="videoData"
      :updateStatus="updateVideoStatus"
    />
  </div>
</template>

<script>
import VideoTableComponent from "./VideoManager/VideoTableComponent.vue";
import VideoService from "@/services/VideoService";

export default {
  components: {
    VideoTableComponent,
  },
  data() {
    return {
      // navLinks: [
      //   { id: 1, name: "Home" },
      //   { id: 2, name: "About" },
      //   { id: 3, name: "Download Agreement" },
      // ],
      tableConfig: {
        tableName: "Video Manager",
        columns: [
          {
            label: "id",
            name: "ID",
            type: "number",
            sortable: true,
            default: null,
          },
          {
            label: "title",
            name: "Title",
            type: "string",
            sortable: true,
            default: null,
          },
          {
            label: "ensemble_name",
            name: "Ensemble",
            type: "string",
            sortable: false,
            default: null,
          },
          {
            label: "networkchannel.name",
            name: "Channel",
            type: "string",
            sortable: true,
            default: null,
          },
          {
            label: "Event/Status",
            name: "Event",
            type: "string",
            sortable: true,
            default: "Live",
          },
        ],
      },
      videoData: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.$updateLoading(1);
      const videos = await VideoService.videos();
      this.videoData = videos.data.tabs[0].results;
      console.log(this.videoData);
      this.$updateLoading(-1);
    },
    updateVideoStatus(videoId, newStatus) {
      const video = this.videoData.find((v) => v.ID === videoId);
      if (video) {
        video["Event/Status"] = newStatus;
      }
    },
  },
};
</script>
