<template>
    <Header v-if="isAuthenticated" />
    <LoaderOverlay />
  <LoaderOverlay />
  <div class="flex min-h-screen bg-gray-100">
    <!-- Left Navigation -->
    <div class="w-1/5 shadow-md" v-if="isAuthenticated">
      <NavComponent />
    </div>
    <!-- Right Content -->
    <div class="flex-grow" :style="isAuthenticated ? 'background-color: white' : 'background-color: #212c4f;'">
      <router-view />
    </div>
  </div>
</template>
<script>
import Header from "/src/components/Header.vue";
import NavComponent from "./views/NavComponent.vue";
import LoaderOverlay from './components/LoaderOverlay.vue';
export default {
  data() {
    return {
      isAuthenticated: false
    };
  },
  components: { NavComponent, Header, LoaderOverlay },
  methods: {
    init() {
      this.isAuthenticated = localStorage.getItem("token") ? true: false;
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss">
@import "@/global-styles/colors.scss";
@import "@/global-styles/typography.scss";

body {
  background: $dark-blue;
  width: 100%;
  // background: white;
}
html {
  width: 100%;
}

h1 {
  @include heading-1;
}

// p {
//   @include large-text-bold(purple);
// }

#app {
  font-family: $system-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $white;
  color: black;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: $white;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
