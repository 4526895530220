<template>
  <div class="container" style="background-color: #212c4f;">
    <div class="request">
      Don't have a liSynce account?
      <router-link to="/request">Request an account</router-link>
    </div>
    <div class="login">
      <h1>
        <span class="faded_h1">li</span>Sync<span class="faded_h1">e</span
        ><span class="green_h1">.</span>
      </h1>
      <h4>Forgot Password</h4>
      <input type="email" name="email" v-model="email" placeholder="Email" />
      <p v-if="error" class="error-message">Please enter the email</p>
      <button @click="submit">Submit</button>
      <h6 style="color: aliceblue;">Changing password here will also change password to <a href="https://tresonamusic.com/">tresonamusic.com</a>, please use the newly changed password to login</h6>
    </div>
  </div>

    <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-90 z-50 overflow-y-auto h-full w-full flex justify-center items-center">
        <PasswordResetSuccess @close="handleClose" />
    </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import PasswordResetSuccess from './PasswordResetSuccess.vue';

const email = ref("");
const success = ref(false);
const error = ref(false);
const showModal = ref(false);
async function submit () {
    if (email.value.length > 0) {
        error.value = false;
        const response = await axios.post("account/forgot/lisyncpassword", {
            useremail: email.value
        });
        if (response.data.status == 'success') {
            showModal.value = true
        } else {
            showModal.value = false
        }
    } else {
        error.value = true;
    };
}

const handleClose = () => {
    showModal.value = false
  };
</script>

<style lang="scss" scoped>
@import "@/global-styles/colors.scss";
@import "@/global-styles/typography.scss";
.container {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}
.login {
  width: 400px;
}

.request {
  position: absolute;
  top: 40px;
  right: 40px;
  color: rgba(255, 255, 255, 0.3);

  a {
    color: white;
  }
}

.faded_h1 {
  @include heading-1(rgba(255, 255, 255, 0.5));
}

.green_h1 {
  @include heading-1($green);
}

h4 {
  margin: 0;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}

input {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

button {
  background: #86bc25;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  margin-top: 20px;

  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  margin-bottom: 40px;
}

a {
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}

.error-message {
  color: red;
  font-size: 14px;
}
</style>
