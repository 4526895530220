<template>
  <div class="header bg-white">
    <div class="logo">
      <span class="faded_h1">li</span><span class="white_h1">Sync</span
      ><span class="faded_h1">e</span><span class="green_h1">.</span>
    </div>
    <div class="user-info" @click="toggleDropdown">
      <!-- <span class="icon">&#x2713;</span> -->
      <span class="username">Hey, {{ name }}</span>
      <div v-if="showDropdown" class="dropdown">
        <button @click="logout">Logout</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["username"],
  data() {
    return {
      showDropdown: false,
      name: this.username? this.username : localStorage.getItem('name')
    };
  },
  mounted() {
    console.log(localStorage.getItem("token"));
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("userId");
      localStorage.removeItem("organization_ids");
      localStorage.removeItem("isFuneralHome");
      this.$router.push("/signin").then(() => {
        // Refresh the page after navigation
        location.reload();
      });
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global-styles/colors.scss";
@import "@/global-styles/typography.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-top: 0;
  border-bottom: 4px solid #86bc25;
  background-color: $dark-blue;
}

.logo {
  font-size: 50px;
  font-weight: bold;
}

.faded_h1 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 50px;
  font-weight: bold;
}

.green_h1 {
  @include heading-1($green);
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;
  cursor: pointer;
}
.white_h1 {
  color: white;
  font-size: 50px;
  font-weight: bold;
}

.icon {
  margin-right: 8px;
  color: white;
}

.username {
  font-size: 16px;
  color: white;
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #ccc;
  background: #fff;
  padding: 8px;
  z-index: 1;
}
</style>
