<template>
  <!-- <div class="container">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" v-model="firstName" required>
      </div>

      <div class="form-group">
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" v-model="lastName" required>
      </div>

      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>
      </div>

      <div class="form-group">
        <label for="note">How did you hear about us?</label>
        <textarea id="note" v-model="note" rows="4" required></textarea>
      </div>

      <vue-recaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired">
      </vue-recaptcha> 
       :disabled="!captchaVerified"
      <button type="submit" >Submit</button>
    </form>
  </div> -->

  <div class="container flex flex-col items-center pt-4 px-4 md:px-0" style="background-color: #212c4f;">
    <div class="request mb-4 text-left w-full flex items-center">
      <!-- Back Icon SVG -->
      <router-link to="/signin" class="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white hover:text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </router-link>
      <span class="mr-2" >Already have an account?</span>
      <router-link to="/signin">Login</router-link>
    </div>
    <div class="w-full md:w-2/3 lg:1/2 mx-auto">
      <h1 class="mb-2">
        <span class="faded_h1">li</span>Sync<span class="faded_h1">e</span><span class="green_h1">.</span>
      </h1>
      <h4>Create Account</h4>
      <!-- Licensing Type Dropdown -->
      <label v-if="error.message" class="error-message text-red-500 text-left">{{ error.message }}</label>
      <div class="form-group mb-4">
        <label for="licensingType" class="text-white block mb-2 mt-4 text-lg font-bold">Licensing Type</label>
        <select v-model="licensingType" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" @change="resetForm">
          <option value="" disabled>Select Licensing Type</option>
          <option value="performance">Performance Synchronization Licensing</option>
          <option value="life-event">Life Event Video Licensing</option>
        </select>
      </div>

      <!-- Life Event Dropdown -->
      <div v-if="licensingType === 'life-event'" class="form-group mb-4">
        <label for="eventType" class="text-white block mb-2 mt-4 text-lg font-bold">Event Type</label>
        <select v-model="eventType" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" @change="resetForm">
          <option value="" disabled>Select Event Type</option>
          <option value="memorial-funeral">Memorial/Funeral</option>
          <option value="wedding">Wedding</option>
          <option value="graduation">Graduation</option>
        </select>
      </div>

      <form v-if="licensingType === 'performance' || (licensingType === 'life-event' && eventType !== 'memorial-funeral' && eventType !== '')" @submit.prevent="handleSubmit">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div class="form-group">
            <input
              type="text"
              id="firstName"
              v-model="firstName"
              required
              placeholder="First Name"
              class="inputclass"
              @blur="validateField('firstName')"
              :class="getFieldClass('firstName')"
            >
            <!-- <p v-if="error.firstName" class="error-message">{{ error.firstName }}</p> -->
          </div>

          <div class="form-group">
            <input
              type="text"
              id="lastName"
              v-model="lastName"
              required
              placeholder="Last Name"
              class="inputclass"
              @blur="validateField('lastName')"
              :class="getFieldClass('lastName')"
            >
            <!-- <p v-if="error.lastName" class="error-message">{{ error.lastName }}</p> -->
          </div>
        </div>

        <div class="form-group">
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="Email"
            class="inputclass"
            @blur="validateField('email')"
            :class="getFieldClass('email')"
          >
          <p v-if="error.email" class="error-message">{{ error.email }}</p>
        </div>

        <div class="form-group">
          <textarea
            id="note"
            v-model="note"
            rows="4"
            required
            placeholder="How did you hear about us?"
            @blur="validateField('note')"
            :class="getFieldClass('note')"
          ></textarea>
          <!-- <p v-if="error.note" class="error-message">{{ error.note }}</p> -->
        </div>

          <!-- <ReCaptchaV2 
          sitekey="6Letp74pAAAAAO8aqkgX--N3qXYf7yMsQwXCgCIr"
          @verify="onVerify"
          @expired="onExpired"
        /> -->
        <vue-recaptcha :sitekey="CAPTCHA_SITE_KEY"
          size="small" 
          theme="light"
          hl="en"
          @verify="onVerify"
          @expire="onExpired"
          class="mt-4"
          ref="vueRecaptcha">
        </vue-recaptcha>

        <button class="submitbutton" :disabled="isFormInvalid() || !captchaVerified || isLoading"
          :style="{ backgroundColor: isFormInvalid() || !captchaVerified || isLoading ? '#b2d8b5' : '#86bc25' }">
          <div v-if="isLoading">
            <!-- Loader Icon or Animation -->
            <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
          </div>
          <div v-else>Create Account</div>
        </button>

      </form>

      <!-- Memorial/Funeral Form -->
      <form v-if="licensingType === 'life-event' && eventType === 'memorial-funeral'" @submit.prevent="handleFuneralSubmit" class="w-full md:w-2/3 lg:w-full mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div class="form-group">
            <input
              type="text"
              id="contactFirstName"
              v-model="contactFirstName"
              required
              placeholder="First Name"
              class="inputclass"
              @blur="validateField('contactFirstName')"
              :class="getFieldClass('contactFirstName')"
            >
            <!-- <p v-if="error.contactFirstName" class="error-message">{{ error.contactFirstName }}</p> -->
          </div>

          <div class="form-group">
            <input
              type="text"
              id="contactLastName"
              v-model="contactLastName"
              required
              placeholder="Last Name"
              class="inputclass"
              @blur="validateField('contactLastName')"
              :class="getFieldClass('contactLastName')"
            >
            <!-- <p v-if="error.contactLastName" class="error-message">{{ error.contactLastName }}</p> -->
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div class="form-group">
            <input
              type="email"
              id="contactEmail"
              v-model="contactEmail"
              required
              placeholder="Email"
              class="inputclass"
              @blur="validateField('contactEmail')"
              :class="getFieldClass('contactEmail')"
            >
            <p v-if="error.contactEmail" class="error-message">{{ error.contactEmail }}</p>
          </div>

          <div class="form-group">
            <div class="flex">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                v-model="password"
                required
                placeholder="Password"
                class="inputclass border-r-none"
                style="border-right: none !important;"
                @blur="validateField('password')"
                :class="getFieldClass('password')"
              >
              <button class="hover:bg-green-600 flex items-center justify-center w-12 rounded-r" type="button" style="height: 60px; margin-top: 20px; background-color: #86bc25;" @click="togglePasswordVisibility">
                <!-- <i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i> -->
                <svg class="h-6 w-6 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="!showPassword">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                </svg>

                <svg class="h-6 w-6 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="showPassword">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                </svg>
              </button>
            </div>
            <p v-if="error.password" class="error-message">{{ error.password }}</p>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div class="form-group">
            <input
              type="text"
              id="contactPhone"
              v-model="contactPhone"
              required
              placeholder="Phone"
              class="inputclass"
              @input="formatPhoneNumber('contactPhone')"
              @blur="validateField('contactPhone')"
              :class="getFieldClass('contactPhone')"
            >
            <!-- <p v-if="error.contactPhone" class="error-message">{{ error.contactPhone }}</p> -->
          </div>

          <div class="form-group">
            <input
              type="text"
              id="contactPhone2"
              v-model="contactPhone2"
              placeholder="Secondary Phone (Optional)"
              class="inputclass"
              @input="formatPhoneNumber('contactPhone2')"
              @blur="validateField('contactPhone2')"
              :class="getFieldClass('contactPhone2')"
            >
            <!-- <p v-if="error.contactPhone2" class="error-message">{{ error.contactPhone2 }}</p> -->
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div class="form-group">
            <input
              type="text"
              id="licenseeName"
              v-model="licenseeName"
              required
              placeholder="Memorial/Funeral Home Name"
              class="inputclass"
              @blur="validateField('licenseeName')"
              :class="getFieldClass('licenseeName')"
            >
            <!-- <p v-if="error.licenseeName" class="error-message">{{ error.licenseeName }}</p> -->
          </div>

          <div class="form-group">
            <input
              type="text"
              id="primaryWebsite"
              v-model="primaryWebsite"
              placeholder="Primary Location Website"
              class="inputclass"
              @blur="validateField('primaryWebsite')"
              :class="getFieldClass('primaryWebsite')"
            >
            <p v-if="error.primaryWebsite" class="error-message">{{ error.primaryWebsite }}</p>
          </div>
        </div>
        <!-- <div class="form-group">
          <input
            type="text"
            id="licenseeAddress"
            v-model="licenseeAddress"
            required
            placeholder="Licensee Address"
            class="inputclass"
            @blur="validateField('licenseeAddress')"
            :class="{ 'error-border': error.licenseeAddress }"
          >
          <p v-if="error.licenseeAddress" class="error-message">{{ error.licenseeAddress }}</p>
        </div> -->

        <div class="form-group">
          <input type="text" id="licenseeAddress" v-model="licenseeAddress" required placeholder="Memorial/Funeral Home Address" class="inputclass" @input="fetchSuggestions('licenseeAddress')" @blur="validateField('licenseeAddress')" :class="getFieldClass('licenseeAddress')">
          <ul v-if="suggestions.length && activeInput === 'licenseeAddress'" class="absolute z-10 bg-white mt-1 rounded-md shadow-lg">
            <li v-for="suggestion in suggestions" :key="suggestion.id" @click="selectSuggestion(suggestion, 'licenseeAddress')" class="cursor-pointer p-3 hover:bg-gray-100">{{ suggestion.place_name }}</li>
          </ul>
          <!-- <p v-if="error.licenseeAddress" class="error-message">{{ error.licenseeAddress }}</p> -->
        </div>

        <div class="form-group">
          <label class="text-white block mt-2 text-base font-bold">
            <input type="checkbox" v-model="sameAsLicenseeAddress"> Billing address same as Licensee Address
          </label>
        </div>

        <!-- <div class="form-group" v-if="!sameAsLicenseeAddress">
          <input
            type="text"
            id="licenseeBillingAddress"
            v-model="licenseeBillingAddress"
            required
            placeholder="Licensee Billing Address"
            class="inputclass"
            @blur="validateField('licenseeBillingAddress')"
            :class="{ 'error-border': error.licenseeBillingAddress }"
          >
          <p v-if="error.licenseeBillingAddress" class="error-message">{{ error.licenseeBillingAddress }}</p>
        </div> -->

        <div class="form-group" v-if="!sameAsLicenseeAddress">
          <input type="text" id="licenseeBillingAddress" v-model="licenseeBillingAddress" required placeholder="Memorial/Funeral Home Billing Address" class="inputclass" @input="fetchSuggestions('licenseeBillingAddress')" @blur="validateField('licenseeBillingAddress')" :class="getFieldClass('licenseeBillingAddress')">
          <ul v-if="suggestions.length && activeInput === 'licenseeBillingAddress'" class="absolute z-10 bg-white mt-1 rounded-md shadow-lg">
            <li v-for="suggestion in suggestions" :key="suggestion.id" @click="selectSuggestion(suggestion, 'licenseeBillingAddress')" class="cursor-pointer p-3 hover:bg-gray-100">{{ suggestion.place_name }}</li>
          </ul>
          <p v-if="error.licenseeBillingAddress" class="error-message">{{ error.licenseeBillingAddress }}</p>
        </div>

        <!-- Dynamic Location Rows with Remove Link -->
        <div v-for="(location, index) in locations" :key="index" class="mb-4 mt-4 p-2 border border-gray-300 rounded-lg">
          <div class="flex justify-between items-center">
            <h5 class="text-white">Location {{ index + 1 }}</h5>
            <button @click="removeLocation(index)" class="text-red-500 text-sm">Remove</button>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div class="form-group">
              <input type="text" v-model="location.name" placeholder="Location Name" class="inputclass">
            </div>
            <div class="form-group">
              <input type="text" v-model="location.address" @input="fetchSuggestions('location', index)" placeholder="Location Address" class="inputclass">
              <ul v-if="location.suggestions.length && activeInput === 'location'" class="absolute z-10 bg-white mt-1 rounded-md shadow-lg">
                <li v-for="suggestion in location.suggestions" :key="suggestion.id" @click="selectSuggestion(suggestion, 'location', index)" class="cursor-pointer p-3 hover:bg-gray-100">{{ suggestion.place_name }}</li>
              </ul>
              <!-- <input type="text" v-model="location.address" placeholder="Location Address" class="inputclass"> -->
            </div>
          </div>
          <input type="text" v-model="location.website" placeholder="Location Website (Optional)" class="inputclass mt-2">
        </div>

        <div class="flex items-center justify-center space-x-2">
          <button @click="toggleTooltip" 
            @mouseover="showTooltipOnDesktop"
            @mouseleave="hideTooltipOnDesktop"
            class="relative focus:outline-none"
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6 text-white">
              <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
            </svg>
            <!-- Tooltip -->
            <div 
              v-if="showTooltip && !isMobile" 
              class="absolute left-full ml-2 p-1 bg-black text-white text-lg rounded-md shadow-md w-[350px] z-10"
            >
              If you are a parent company/chain and have more than one funeral home or mortuary, please use the 'Add Location' button for each branch.
            </div>
          </button>
          <button type="button" @click="addLocation" class="text-white px-4 py-2 rounded-md hover:bg-green-600 mt-2 mb-2 font-bold" style="background-color: #86bc25;">+ Add Location</button>
        </div>
        <span v-if="showTooltip && isMobile" class="text-white block mb-2 mt-2 text-base font-bold">
          If you are a parent company/chain and have more than one funeral home or mortuary, please use the 'Add Location' button for each branch.
        </span>

        <!-- Entity Type Dropdown -->
        <div class="form-group">
          <!-- <label for="entityType" class="text-white block mb-2 mt-4 text-lg font-bold">Entity Type</label> -->
          <select v-model="entityType" class="inputclass entity-select"
            @blur="validateField('entityType')"
            :class="getFieldClass('entityType')"
          >
            <option value="" disabled>Select Entity Type</option>
            <option value="Individual/Sole Proprietor">Individual/Sole Proprietor</option>
            <option value="Corporation">Corporation</option>
            <option value="Partnership">Partnership</option>
            <option value="LLC">LLC</option>
            <option value="Trust/Estate">Trust/Estate</option>
            <option value="Other">Other (Please Enter)</option>
          </select>
        </div>

        <!-- Input Field for Other Entity Type -->
        <div v-if="entityType === 'Other'" class="form-group">
          <input
            type="text"
            id="otherEntityType"
            v-model="otherEntityType"
            required
            placeholder="Please enter the entity type"
            class="inputclass"
            @blur="validateField('otherEntityType')"
            :class="getFieldClass('otherEntityType')"
          >
          <p v-if="error.otherEntityType" class="error-message">{{ error.otherEntityType }}</p>
        </div>

        <label class="block text-white mb-2 mt-4 text-lg font-bold">
          <input type="checkbox" v-model="isFamicMainChecked" @change="validateFAMIC()">
          I am a member of FAMIC (check all that apply)
        </label>

        <!-- FAMIC Membership Section -->
        <div class="form-group p-4 rounded-lg mb-4 mt-2" style="background-color: rgba(255, 255, 255, 0.2);" v-if="isFamicMainChecked">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label class="block text-white mb-2 text-left">
              <input type="checkbox" value="National Funeral Directors Association" v-model="famicMemberships" @change="uncheckNoneIfOtherSelected">
              National Funeral Directors Association
            </label>
            <label class="block text-white mb-2 text-left">
              <input type="checkbox" value="International Order of the Golden Rule" v-model="famicMemberships" @change="uncheckNoneIfOtherSelected">
              International Order of the Golden Rule
            </label>
            <label class="block text-white mb-2 text-left">
              <input type="checkbox" value="Selected Independent Funeral Homes" v-model="famicMemberships" @change="uncheckNoneIfOtherSelected">
              Selected Independent Funeral Homes
            </label>
            <label class="block text-white mb-2 text-left">
              <input type="checkbox" value="National Funeral Directors & Morticians Association" v-model="famicMemberships" @change="uncheckNoneIfOtherSelected">
              National Funeral Directors & Morticians Association
            </label>

            <!-- Checkbox for State Association -->
            <label class="block text-white mb-2 text-left">
              <input type="checkbox" v-model="isStateAssociationSelected" @change="handleStateAssociationChange">
              State Association of ______ (Please Choose State)
            </label>

            <!-- Checkbox for None -->
            <!-- <label class="block text-white mb-2 text-left">
              <input type="checkbox" value="None" v-model="famicMemberships" @change="uncheckOtherIfNoneSelected">
              None (I am not a member of any of these organizations)
            </label> -->
          </div>

          <!-- Conditional Dropdown for State Selection after checkboxes -->
          <div v-if="isStateAssociationSelected" class="mt-2">
            <select v-model="selectedState" @change="updateStateAssociation" class="inputclass entity-select">
              <option value="" disabled>Select State</option>
              <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
            </select>
          </div>

          <p v-if="error.famicMemberships" class="error-message">{{ error.famicMemberships }}</p>
        </div>
        <vue-recaptcha :sitekey="CAPTCHA_SITE_KEY"
          size="small" 
          theme="light"
          hl="en"
          @verify="onVerify"
          @expire="onExpired"
          class="mt-2"
          ref="vueRecaptcha">
        </vue-recaptcha>

        <button class="submitbutton" :disabled="isFuneralFormInvalid() || !captchaVerified || isLoading"
          :style="{ backgroundColor: isFuneralFormInvalid() || !captchaVerified || isLoading ? '#b2d8b5' : '#86bc25' }">
          <div v-if="isLoading">
            <!-- Loader Icon or Animation -->
            <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
          </div>
          <div v-else>Create Account</div>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import { VueRecaptcha } from 'vue-recaptcha';
import vueRecaptcha from 'vue3-recaptcha2';
import { useRouter } from "vue-router";
import { useStore } from 'vuex';

export default {
  name: "RequestView",
  components: {
    vueRecaptcha 
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      note: '',
      CAPTCHA_SITE_KEY: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      error: {},
      captchaVerified: false,
      licensingType: '',
      eventType: '',
      showTooltip: false,
      isMobile: false,
      isLoading: false,
      error: {
        message: ''
      },
      // Fields for Funeral Form
      entityType: '',
      otherEntityType: '',
      isFamicMainChecked: false,
      isFAMICMember: false, // Boolean to check if user selected any membership
      famicMemberships: [], // Array to store selected memberships
      isStateAssociationSelected: false, // To track if State Association is checked
      selectedState: '', // The state selected from the dropdown
      states: [ // List of U.S. states
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
        'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
        'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
        'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah',
        'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
      ],
      licenseeName: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
      contactPhone2: '',
      primaryWebsite: '',
      password: '',
      licenseeAddress: '',
      billingQuery: '',
      licenseeBillingAddress: '',
      sameAsLicenseeAddress: false,
      locations: [],
      showPassword: false,
      query: '',
      suggestions: [],
      activeInput: '',
      funeralForm: {},
      validationStatus: {},
    };
  },
  mounted () {
    this.initializeValidationStatus();
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  },
  watch: {
    sameAsLicenseeAddress(newValue) {
      if (newValue) {
        this.licenseeBillingAddress = this.licenseeAddress;
      } else {
        this.licenseeBillingAddress = '';
      }
    },
    licenseeAddress(newAddress) {
      if (this.sameAsLicenseeAddress) {
        this.licenseeBillingAddress = newAddress;
      }
    },
    isFamicMainChecked(newValue) {
      if (!newValue) {
        // If the FAMIC main checkbox is unchecked, clear the memberships
        this.famicMemberships = [];
        this.isFAMICMember = false;
        this.handleStateAssociationChange();
      }
    }
  },
  methods: {
    uncheckOtherIfNoneSelected() {
      if (this.famicMemberships.includes('None')) {
        this.famicMemberships = ['None'];
        this.isStateAssociationSelected = false; // Deselect State Association
        this.selectedState = ''; // Clear selected state
      }
      this.validateFAMIC();
    },
    uncheckNoneIfOtherSelected() {
      const noneIndex = this.famicMemberships.indexOf('None');
      if (noneIndex !== -1) {
        this.famicMemberships.splice(noneIndex, 1); // Uncheck "None"
      }
      this.validateFAMIC();
    },
    onVerify(response) {
      this.captchaVerified = true;
    },
    onExpired() {
      console.log('CAPTCHA expired');
      this.captchaVerified = false;
      this.$refs.vueRecaptcha.reset();
    },
    handleSubmit() {
      if (this.isFormInvalid()) {
        alert('Please correct the errors before submitting.');
        return;
      }
      this.isLoading = true;
      const data = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        note: this.note
      };
      axios.post(axios.defaults.baseURL + 'account/lisynce/sendEmail', data)
        .then(response => {
          alert('Request submitted successfully.');
          this.resetForm();
        })
        .catch(error => {
          console.error('Error submitting form:', error);
        });
        this.isLoading = false;
    },
    handleFuneralSubmit() {
      this.validateFAMIC()
      if (this.isFuneralFormInvalid()) {
        alert('Please correct the errors before submitting.');
        return;
      }
      this.isLoading = true;
      const selectedEntityType = this.entityType === 'Other' ? this.otherEntityType : this.entityType;

      this.funeralForm = {
        licenseeName: this.licenseeName,
        contactFirstName: this.contactFirstName,
        contactLastName: this.contactLastName,
        contactEmail: this.contactEmail,
        contactPhone: this.contactPhone,
        contactPhone2: this.contactPhone2 ?? '',
        primaryWebsite: this.primaryWebsite ?? '',
        password: this.password,
        entityType: selectedEntityType,
        licenseeAddress: this.licenseeAddress,
        famicMemberships: this.famicMemberships,
        isFAMICMember: this.isFAMICMember,
        licenseeBillingAddress: this.sameAsLicenseeAddress? this.licenseeAddress : this.licenseeBillingAddress,
        sameAsLicenseeAddress: this.sameAsLicenseeAddress,
        locations: this.locations,
        numLocations: this.locations.length + 1
      };


      // Call the create account API
      axios.post(`${axios.defaults.baseURL}account/lisynce/create`, { funeralForm: this.funeralForm })
        .then(response => {
          // Check if the account creation was successful
          if (response.data.success) {
            console.log('Account created successfully. Proceeding to create agreement.');
            this.funeralForm.orgId = response.data.success.orgId;

            // Call the agreement creation API if the account was created
            axios.post(`${axios.defaults.baseURL}account/lisynce/createAgreement`, this.funeralForm, { responseType: 'blob' })
              .then(agreementResponse => {
                // Handle the agreement response
                const url = URL.createObjectURL(new Blob([agreementResponse.data], { type: 'application/pdf' }));
                this.$store.dispatch('updateDocument', url);
                console.log('from reqest' ,  this.funeralForm);
                this.$store.dispatch('updateFuneralForm', this.funeralForm);
                this.$router.push({ name: 'FuneralAgreement' });
                this.isLoading = false;
              })
              .catch(error => {
                console.error('Error retrieving agreement:', error);
                alert('An error occurred while creating the agreement. Please try again.');
              });
          } else {
            // Handle any errors from the account creation API
            console.error('Account creation failed:', response.data.error);
            if (response.data.error && response.data.error.message) {
              this.error.message = response.data.error.message; // assuming the error message is in this path
            } else {
              console.error('Error submitting form:', error);
            }
            this.isLoading = false;
          }
        })
        .catch(error => {
          console.error('Error creating account:', error);
          alert('Account creation failed. Please try again.');
          this.isLoading = false;
        });
        this.isLoading = true;
      // Submit logic for Memorial/Funeral
      // axios.post(axios.defaults.baseURL + 'account/lisynce/createAgreement', this.funeralForm, {responseType: 'blob'})
      //   .then(response => {
      //     // logic for Memorial/Funeral agreement to be previwed and displayed/downloadable
      //     // console.log(response.data.document);
      //     // this.$store.dispatch('updateDocument', response.data.document); // Store the document in Vuex
      //     const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      //     this.$store.dispatch('updateDocument', url);
      //     this.$store.dispatch('updateFuneralForm', this.funeralForm);
      //     this.$router.push({ name: 'FuneralAgreement' });
      //   })
      //   .catch(error => {
      //     console.error('Error retrieving agreement:', error);
      //   });
    },
    formatPhoneNumber(field) {
      let input = this[field];

      // Remove all non-digit characters
      input = input.replace(/\D/g, '');

      // Format the input to (000) 000-0000
      if (input.length > 0) {
        input = input.substring(0, 10);
        if (input.length <= 3) {
          input = '(' + input;
        } else if (input.length <= 6) {
          input = '(' + input.substring(0, 3) + ') ' + input.substring(3);
        } else {
          input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6);
        }
      }
      this[field] = input;
    },
     // Validate FAMIC checkboxes
     validateFAMIC() {
      if(!this.isFamicMainChecked) {
        this.isFAMICMember = false
      }else if (this.famicMemberships.length === 0 && !this.isStateAssociationSelected && this.isFamicMainChecked) {
        this.error.famicMemberships = 'Please select at least one organization.';
        this.isFAMICMember = false;
      } else {
        this.error.famicMemberships = '';
        this.isFAMICMember = true;
      }
    },
    // Handle when the "State Association" checkbox is checked/unchecked
    handleStateAssociationChange() {
      if (!this.isStateAssociationSelected) {
        // Remove state association entry if unchecked
        const index = this.famicMemberships.findIndex(item => item.includes('State Association of'));
        if (index !== -1) this.famicMemberships.splice(index, 1);
        this.selectedState = ''; // Reset selected state
      }
      this.validateFAMIC();
    },
    // Update the FAMIC membership list when a state is selected
    updateStateAssociation() {
      // Remove previous state association if exists
      const index = this.famicMemberships.findIndex(item => item.includes('State Association of'));
      if (index !== -1) this.famicMemberships.splice(index, 1);

      // Add new state association
      if (this.selectedState) {
        this.famicMemberships.push(`State Association of ${this.selectedState}`);
      }
      this.validateFAMIC();
    },
    initializeValidationStatus () {
      this.validationStatus = {
        firstName: 'invalid',
        lastName: 'invalid',
        email: 'invalid',
        note: 'invalid',
        licenseeName: 'invalid',
        contactFirstName: 'invalid',
        contactLastName: 'invalid',
        contactEmail: 'invalid',
        contactPhone: 'invalid',
        contactPhone2: 'valid',
        primaryWebsite: 'invalid',
        password: 'invalid',
        entityType: 'invalid',
        otherEntityType: 'invalid',
        licenseeAddress: 'invalid',
        licenseeBillingAddress: 'invalid'
      }
    },
    validateField(field) {
      if (field === 'email' || field === 'contactEmail') {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.error[field] = re.test(this[field]) ? '' : 'Please enter a valid email address.';
        this.validationStatus[field] = re.test(this[field]) ? 'valid' : 'invalid';
      } else if (field === 'password') {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        this.error.password = re.test(this.password) ? '' : 'Password must contain 1-lowercase, 1-uppercase, 1-numeric, 1-special character.';
        this.validationStatus.password = re.test(this.password) ? 'valid' : 'invalid';
      }  else if (field === 'contactPhone') {
        const re = /^\(\d{3}\) \d{3}-\d{4}$/;
        this.error.contactPhone = re.test(this.contactPhone) ? '' : 'Phone number must be exactly 10 digits.';
        this.validationStatus.contactPhone = re.test(this.contactPhone) ? 'valid' : 'invalid';
      } else if (field === 'contactPhone2') {
        const re = /^\(\d{3}\) \d{3}-\d{4}$/;
        if (this.contactPhone2 != '') {
          this.error.contactPhone2 = re.test(this.contactPhone2) ? '' : 'Phone number must be exactly 10 digits.';
          this.validationStatus.contactPhone2 = re.test(this.contactPhone2) ? 'valid' : 'invalid';
        } else if (this.contactPhone2 == '') {
          this.validationStatus.contactPhone2 = 'valid';
          this.error.contactPhone2 = null;
        }
      } else if (field === 'primaryWebsite') {
        const re = /^https?:\/\/.+\..+$/;
        this.error[field] = re.test(this[field]) ? '' : 'Please enter a valid website URL starting with http:// or https://';
        this.validationStatus[field] = re.test(this[field]) ? 'valid' : 'invalid';
      } else if (field === 'entityType') {
        this.error.entityType = this.entityType ? '' : 'Please select an entity type.';
        this.validationStatus.entityType = this.entityType ? 'valid' : 'invalid';
      } else if (field === 'otherEntityType' && this.entityType === 'Other') {
        this.error.otherEntityType = this.otherEntityType.trim() ? '' : 'Please enter the other entity type.';
        this.validationStatus.otherEntityType = this.otherEntityType.trim() ? 'valid' : 'invalid';
      } else {
        this.error[field] = this[field].trim().length === 0 ? 'This field is required.' : '';
        this.validationStatus[field] = this[field].trim().length === 0 ? 'invalid' : 'valid';
      }
    },
    getFieldClass(field) {
      return this.validationStatus[field] === 'valid' ? 'success-border' : 'error-border';
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
    toggleTooltip() {
      // Toggle tooltip only for mobile screens
      if (this.isMobile) {
        this.showTooltip = !this.showTooltip;
      }
    },
    showTooltipOnDesktop() {
      // Show tooltip on hover for non-mobile screens
      if (!this.isMobile) {
        this.showTooltip = true;
      }
    },
    hideTooltipOnDesktop() {
      // Hide tooltip on hover out for non-mobile screens
      if (!this.isMobile) {
        this.showTooltip = false;
      }
    },
    isFormInvalid() {
      return !this.firstName.trim() || !this.lastName.trim() || !this.note.trim() || !!this.error.email;
    },
    isFuneralFormInvalid() {
      if (!this.isFamicMainChecked) {
        this.isStateAssociationSelected = false
        this.handleStateAssociationChange()
      }
      return !this.licenseeName.trim() || !this.contactFirstName.trim() || !this.contactLastName.trim() || !this.primaryWebsite.trim() ||
        !this.contactEmail.trim() || !this.contactPhone.trim() || !this.password.trim() || !this.licenseeAddress.trim() ||
        (!this.sameAsLicenseeAddress && !this.licenseeBillingAddress.trim()) || !!this.error.contactEmail || !!this.error.password || !!this.error.contactPhone || !!this.error.contactPhone2 || !!this.error.primaryWebsite ||
        !this.entityType.trim() || (this.entityType === 'Other' && !this.otherEntityType.trim()) || (this.famicMemberships.length === 0 && this.isFamicMainChecked);
    },
    resetForm() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.note = '';
      this.licenseeName = '';
      this.contactFirstName = '';
      this.contactLastName = '';
      this.contactEmail = '';
      this.contactPhone = '';
      this.contactPhone2 = '';
      this.primaryWebsite = '';
      this.password = '';
      this.entityType = '';
      this.otherEntityType = '';
      this.isFamicMainChecked = false;
      this.isFAMICMember= false; // Boolean to check if user selected any membership
      this.famicMemberships= []; // Array to store selected memberships
      this.isStateAssociationSelected = false; // To track if State Association is checked
      this.selectedState = '';
      this.licenseeAddress = '';
      this.licenseeBillingAddress = '';
      this.showTooltip = false;
      this.sameAsLicenseeAddress = false;
      this.error = {};
      this.captchaVerified = false;
      this.funeralForm = {};
      this.$nextTick(() => {
        if (this.$refs.vueRecaptcha) {
          this.$refs.vueRecaptcha.reset();
        }
      });
      // this.$refs.vueRecaptcha.reset();
    },
    addLocation() {
      this.locations.push({ name: '', address: '', website: '', suggestions: [] });
    },
    removeLocation(index) {
      this.locations.splice(index, 1);
    },
    fetchSuggestions(inputType, index = null) {
      let queryValue = inputType === 'licenseeAddress' ? this.licenseeAddress : inputType === 'licenseeBillingAddress' ? this.licenseeBillingAddress : this.locations[index].address;
      if (!queryValue) {
        if (inputType === 'licenseeAddress') this.suggestions = [];
        if (inputType === 'licenseeBillingAddress') this.suggestions = [];
        if (inputType === 'location') this.locations[index].suggestions = [];
        return;
      }

      const API_URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(queryValue)}.json?access_token=pk.eyJ1IjoiYnJ5YW4tcG90dHMiLCJhIjoiY2xuZWh6dnk1MGNyNjJvcGVjbzFzeGtpZCJ9.h1ubFAo_rM9sj59jy-OeCw`;

      axios.get(API_URL)
        .then((response) => {
          if (inputType === 'licenseeAddress' || inputType === 'licenseeBillingAddress') {
            this.suggestions = response.data.features;
            this.activeInput = inputType;
          } else if (inputType === 'location') {
            this.locations[index].suggestions = response.data.features;
            this.activeInput = 'location';
          }
        })
        .catch((error) => {
          console.error('Error fetching address suggestions:', error);
        });
    },
    selectSuggestion(suggestion, inputType, index = null) {
      if (inputType === 'licenseeAddress') {
        this.licenseeAddress = suggestion.place_name;
        // this.query = suggestion.place_name;
      } else if (inputType === 'licenseeBillingAddress') {
        this.licenseeBillingAddress = suggestion.place_name;
        // this.billingQuery = suggestion.place_name;
      } else if (inputType === 'location') {
        this.locations[index].address = suggestion.place_name;
        // this.locations[index].query = suggestion.place_name;
      }
      this.suggestions = [];
      if (index !== null) this.locations[index].suggestions = [];
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/global-styles/colors.scss";
@import "@/global-styles/typography.scss";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  margin: 0 auto;
}

// .form-row {
//   @apply flex -mx-2; /* Using Tailwind CSS for flex layout */
// }

// .form-group {
//   @apply w-full; /* Tailwind full-width */
// }

.login {
  width: 400px;
}

.request {
  // position: absolute;
  // top: 40px;
  // right: 40px;
  color: rgba(255, 255, 255, 0.3);

  a {
    color: white;
  }

  margin-top: 10px;
  padding-right: 20px;
}

.faded_h1 {
  @include heading-1(rgba(255, 255, 255, 0.5));
}

.green_h1 {
  @include heading-1($green);
}

h4 {
  margin: 0;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
}
/* Target the autofill styling for modern browsers */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0.2) !important; /* Set your desired background color */
  color: white !important; /* Set your desired text color */
  border: 1px solid rgba(255, 255, 255, 0.2) !important; /* Match your input styles */
  border-radius: 4px;
}

/* Reset autofill styles for Mozilla Firefox */
input:-moz-autofill,
textarea:-moz-autofill,
select:-moz-autofill {
  background-color: rgba(255, 255, 255, 0.2) !important; /* Set your desired background color */
  color: white !important; /* Set your desired text color */
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px;
}

.inputclass {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}
textarea {
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  padding-left: 20px;
  margin-top: 20px;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
}

.submitbutton {
  background: #86bc25;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border: none;
  margin-top: 20px;

  height: 60px;
  width: 100%;

  font-size: 20px;
  color: white;
  margin-bottom: 40px;
}

a {
  font-size: 16px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}

.error-message {
  color: red;
  font-size: 14px;
  line-height: 15px;
}

/* Add CSS for validation states */
.error-border {
  border-color: red;
}
.success-border {
  border-color: #86bc25;
}

.entity-select option {
  color: rgb(0, 0, 0);
}

</style>

