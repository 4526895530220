<template>
  <div class="container mx-auto p-4 bg-white">
    <h1 class="text-2xl font-bold mb-6 text-gray-900">Billing Details</h1>
    
    <!-- Card and Billing Info -->
    <div class="flex flex-wrap lg:flex-nowrap items-start mb-8">

      <!-- Billing Info Section -->
      <div class="w-full xl:w-2/3 md:w-2/3 p-4" style="padding-right: 100px;">
        <!-- <h2 class="text-lg font-bold mb-2 text-left">Billing Address</h2> -->
        <div class="mb-4">
          <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
          <input
            id="address"
            type="text"
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            :value="billing.address"
            disabled
          />
        </div>
        <div class="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label for="city" class="block text-sm font-medium text-gray-700">City</label>
            <input
              id="city"
              type="text"
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              :value="billing.city"
              disabled
            />
          </div>
          <div>
            <label for="state" class="block text-sm font-medium text-gray-700">State</label>
            <input
              id="state"
              type="text"
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              :value="billing.state"
              disabled
            />
          </div>
        </div>

        <!-- Country Input -->
        <div class="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
            <input
              id="country"
              type="text"
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              :value="billing.country"
              disabled
            />
          </div>
          <div>
            <label for="zip" class="block text-sm font-medium text-gray-700">Zip</label>
            <input
              id="zip"
              type="text"
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
              :value="billing.zip"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- Card Section -->
      <div class="w-full xl:w-1/3 md:w-2/3p-4 pt-6">
        <div class="relative border rounded-xl shadow-lg p-6 bg-gradient-to-r from-gray-800 to-gray-500 text-white" style="width: 400px;">

          <!-- Card Information -->
          <h2 class="text-lg font-bold mt-8">{{ billing.cctype }}</h2>
          <!-- Chip Icon -->
          <div class="bg-yellow-400 w-10 h-6 rounded-sm"></div>
          <p class="text-2xl font-mono tracking-widest mt-4">
            {{ formatLastFour(billing.lastfour, billing.cctype) }}
          </p>
          <div class="flex justify-between items-center mt-4 text-sm">
            <div>
              <p>VALID THRU</p>
              <p class="font-mono tracking-widest">{{ billing.ccexpmonth }}/{{ billing.ccexpyear }}</p>
            </div>
            <div>
              <p>NAME</p>
              <p class="font-mono tracking-widest">{{ billing.name }}</p>
            </div>
          </div>
          <!-- Card Logo -->
          <div class="absolute right-2 top-3">
            <img
              v-if="billing.cctype"
              :src="`/img/cc/logos/${billing.cctype}.png`"
              alt="Card Type"
              class="h-8 w-12 object-contain"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Subscription Details -->
    <div class="bg-gray-100 p-4 mb-8 rounded-md shadow-md" v-if="subscription">
      <h2 class="text-lg font-bold mb-2 text-gray-900">Agreement Details</h2>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
        <div>
          <label class="block text-sm font-medium text-gray-700">Type</label>
          <p class="text-gray-900 font-medium">{{ subscription.subscriptiontype.name }}</p>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Status</label>
          <p class="text-gray-900 font-medium">{{ subscription.active == 1 ? "Active" : "Inactive" }}</p>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">Start Date</label>
          <p class="text-gray-900 font-medium">{{ subscription.startdate }}</p>
        </div>
        <div>
          <label class="block text-sm font-medium text-gray-700">End Date</label>
          <p class="text-gray-900 font-medium">{{ subscription.enddate }}</p>
        </div>
      </div>
    </div>

    <h2 class="text-lg font-bold mb-4">Transactions</h2>
    <div>
      <table class="w-full table-auto border-collapse border border-gray-200">
        <thead>
          <tr class="bg-gray-100">
            <th class="border border-gray-300 px-4 py-2">Type</th>
            <th class="border border-gray-300 px-4 py-2">Amount</th>
            <!-- <th class="border border-gray-300 px-4 py-2">Transaction ID</th> -->
            <!-- <th class="border border-gray-300 px-4 py-2">Status</th>
            <th class="border border-gray-300 px-4 py-2">Start Date</th>
            <th class="border border-gray-300 px-4 py-2">End Date</th> -->
            <th class="border border-gray-300 px-4 py-2">Created</th>
            <!-- <th class="border border-gray-300 px-4 py-2">Auto-Renew</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="transaction in paginatedTransactions"
            :key="transaction.id"
            class="text-center"
          >
            <td class="border border-gray-300 px-4 py-2">{{ subscription.subscriptiontype.name }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ formatAmount(transaction.amount) }}</td>
            <!-- <td class="border border-gray-300 px-4 py-2">
              {{ subscription.transactions[0]?.id || "N/A" }}
            </td> -->
            <!-- <td class="border border-gray-300 px-4 py-2">
              {{ subscription.active == 1 ? "Active" : "Inactive" }}
            </td>
            <td class="border border-gray-300 px-4 py-2">{{ subscription.startdate }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ subscription.enddate }}</td> -->
            <td class="border border-gray-300 px-4 py-2">{{ formatDate(transaction.created) }}</td>
            <!-- <td class="border border-gray-300 px-4 py-2">
              {{ subscription.autorenew ? "Yes" : "No" }}
            </td> -->
          </tr>
        </tbody>
      </table>

      <p class="text-sm text-gray-500 mt-2 text-right">
        * Transaction fee is included in the total amount for this agreement.
      </p>


      <!-- Pagination -->
      <div class="flex justify-end items-center mt-4 space-x-2">
        <button
          class="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          :disabled="currentPage === 1"
          @click="prevPage"
        >
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button
          class="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
          :disabled="currentPage === totalPages"
          @click="nextPage"
        >
          Next
        </button>
      </div>
    </div>

    <p class="text-sm text-gray-500 mt-2 text-center" style="position: fixed; bottom: 0; padding-bottom: 30px;">
      For any questions regarding changes to your account, billing inquiries, updating your billing address, or terminating your liSynce Agreement, please contact us at 
      <a href="mailto:info@tresonamultimedia.com" class="text-blue-500 hover:underline">info@tresonamultimedia.com</a>.
    </p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Billing",
  data() {
    return {
      subscription: null,
      transactions: [],
      billing: {},
      currentPage: 1,
      itemsPerPage: 5
    };
  },
  computed: {
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.transactions.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.transactions.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$updateLoading(1);
        const response = await axios.post(
          axios.defaults.baseURL + "lisyncepayment/getbilling",
          { user_id: localStorage.getItem("userId") }
        );
        this.subscription = response.data.data.subscriptions;
        this.transactions = this.subscription['transactions'];
        this.billing = response.data.data.billing;
      } catch (error) {
        console.error("Failed to load transactions:", error);
      } finally {
        this.$updateLoading(-1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    formatDate(datetime) {
      if (!datetime) return "";
      const date = new Date(datetime);
      return date.toISOString().slice(0, 19).replace("T", " ");
    },
    formatAmount(amount) {
      if (typeof amount === "number") {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(amount);
      }
      return amount;
    },
    formatLastFour(lastFour, cardType) {
      if (!lastFour) {
        return "0000"; // Default value if lastFour is undefined or null
      }

      const str = lastFour.toString().padStart(4, "0"); // Ensure 4 digits with leading zeros

      if (cardType === "American Express") {
        // Format as 4-6-5 for Amex
        return `**** ****** *${str}`;
      }

      // Default format for non-Amex cards
      return `**** **** **** ${str}`;
    }
  },
};
</script>
