import { createApp } from "vue";
import "./assets/styles/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import Loader from './services/LoaderOverlay';

// axios.defaults.baseURL = "https://thespinnetwork.dev.aimitservices.com/api/v1/";
axios.defaults.baseURL = process.env.VUE_APP_APIURL;
axios.defaults.headers["Content-Type"] = `application/x-www-form-urlencoded`;

createApp(App).use(store).use(router).use(Loader).mount("#app");
