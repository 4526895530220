<template>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-5xl">
      <h2 class="text-xl font-bold text-gray-900 mb-4">Step 1: Video Information</h2>
      
        <div class="space-y-4">
            <div :class="[
                    form.school == '' || form.ensemble == '' ? 'border-red-100' : 'border-green-100', 
                    'p-4', 
                    'bg-white', 
                    'shadow-md', 
                    'rounded-md', 
                    'border-2', 
                    'relative'
                  ]">
              <div class="absolute top-0 left-0 right-0 px-3 py-1 mt-6t" :class="[
                      !form.school || !form.ensemble ? 'bg-red-100' : 'bg-green-100', 
                    ]">
                <h4 class=" font-bold text-sm text-left" :class="[
                      !form.school || !form.ensemble ? 'text-red-700' : 'text-green-700', 
                    ]">Ensemble/Location Information</h4>
              </div>
              <div v-if="!showSchool">
                <p class="text-gray-500 mb-4 text-xs text-left mt-6">
                  Select the name of the organization in the dropdown below.
                </p>
                <p class="text-gray-500 mb-2 text-lg text-left">
                  Select Organization
                </p>
                <div class="mb-4" v-if="form.school == ''">
                  <div class="relative" v-if="form.school == ''">
                    <select
                      v-model="form.school"
                      @change="onSchoolSelect($event)"
                      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled selected>Select Organization</option>
                      <option v-for="school in filteredSchool" :key="school.id" :value="school.id">
                        {{ school.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <p class="text-gray-900 mb-1 text-xs text-left">
                      School/Organization Name:
                    </p>
                    <hr class="mt-2">
                    <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                      {{ selectedSchool.name }}
                    </p>
                    <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                      {{ selectedSchool.address }}
                    </p>
                    <div class="text-left"> <!-- This ensures the button inside aligns to the left -->
                      <button @click='clearSchool()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
                <p class="text-gray-500 mb-4 text-xs text-left mt-6" v-if="form.school != ''">
                  Please select the name of the reporting Ensemble (for performance videos) or Organization Location (for life event videos) in the dropdown below.
                </p>
                <p class="text-gray-500 mb-2 text-lg text-left"  v-if="form.school != ''">
                  Select Ensemble/Location
                </p>
                <div class="mb-4 mt-6" v-if="form.school != ''">
                  <select
                    v-model="form.ensemble"
                    @change="onEnsembleSelect($event)"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option value="" disabled selected>Select Ensemble/Location</option>
                    <option v-for="ensemble in ensembles" :key="ensemble.id" :value="ensemble.id">
                      {{ ensemble.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div v-else class="mt-8 flex">
                <div class="flex-1">
                  <p class="text-gray-900 mb-1 text-xs text-left">
                    School/Organization Name:
                  </p>
                  <hr class="mt-2">
                  <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                    {{ selectedSchool.name }}
                  </p>
                  <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                    {{ selectedSchool.address1 }}
                  </p>
                  <p class="text-gray-500 mb-1 text-xs text-left font-normal">
                    {{ selectedSchool.address }}
                  </p>
                  <div class="text-left"> <!-- This ensures the button inside aligns to the left -->
                    <button @click='clearSchool()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                      Clear
                    </button>
                  </div>
                </div>
                <div class="flex-1">
                  <p class="text-gray-900 mb-1 text-xs text-left">
                    Ensemble/Location Information:
                  </p>
                  <hr class="mt-2">
                  <p class="text-gray-500 mb-1 text-lg text-left font-normal">
                    {{ selectedEnsemble.name }}
                  </p>
                  <div class="text-left"> <!-- This ensures the button inside aligns to the left -->
                    <button @click='clearEnsemble()' class="text-white bg-yellow-400 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-700 focus:ring-opacity-50 rounded-lg px-2 py-1 mt-2 text-xs">
                      Clear 
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4 bg-red-100 shadow-md rounded-md border-2 border-red-100 relative mt-6" v-if="errors.length>0">
              <p v-for="error in errors" class="text-red-700 mb-1 text-xs text-left">
                    {{'* ' + error}}
                  </p>
            </div>
        </div>
      
        <div class="mt-8 flex justify-between">
            <button class="bg-gray-200 text-gray-800 rounded-lg px-5 py-2 hover:bg-gray-300" @click="emitClose()">Cancel</button>
            <button @click="next()" class="bg-red-500 text-white rounded-lg px-5 py-2 hover:bg-red-600">Next</button>
        </div>
    </div>
  </template>

  <script setup>
  import { defineEmits, onMounted, ref, computed, watch } from 'vue';
  import axios from 'axios';

  const emit = defineEmits(['next', 'close']);

  const form = ref({
    school: '',
    ensemble: '0',
  });
  const errors = ref([]);
  const userId = ref(localStorage.getItem('userId'));
  const isFuneralHome = ref(localStorage.getItem('isFuneralHome') == 1 ? true : false);
  const schools = ref([]);
  const ensembles = ref([]);
  const showSchool = ref(false);
  const searchSchoolQuery = ref ('');
  const searchEnsembleQuery = ref ('');

  const selectedSchool = ref({
    id: '',
    name: '',
    address1: '',
    address: '',
  });
  const selectedEnsemble = ref({
    id: '',
    name: '',
  });
  let searchTimeout = null;
onMounted(() => {
  // if (userId.value != 1) {
    searchOrganisation();
  // }
});
async function fetchEnsemblesForOrganization(organizationId) {
  try {
    const response = await axios.post(axios.defaults.baseURL + "organization/ensemble/search/", {
      organization_id: organizationId
    });
    ensembles.value = response.data.results; // Assuming the response data is the array of ensembles
  } catch (error) {
    console.error('Error fetching ensembles:', error);
    ensembles.value = []; // Reset on error
  }
};
//   watch(searchSchoolQuery, async (newValue, oldValue) => {
//   if (newValue !== oldValue && newValue !='') {
//     if (searchTimeout) clearTimeout(searchTimeout); // Clear existing timeout
//     searchTimeout = setTimeout(() => {
//       searchOrganisation(); // Call your function after 1 second of inactivity
//     }, 1000);
//   }
// });

  async function searchOrganisation() {
    // if(searchSchoolQuery.value !=''){
      const orgsnisations = await axios.post(axios.defaults.baseURL + "organization/name-search/", {
      organizations: JSON.parse(localStorage.getItem("organization_ids"))
    });
    schools.value = orgsnisations.data.results;
    // console.log(orgsnisations.data);
    // } else{
    //   return
    // }
  };
  const filteredSchool = computed(() => {
    return searchSchoolQuery.value ? schools.value.filter(e => e.name.toLowerCase().includes(searchSchoolQuery.value.toLowerCase())) : schools.value;
  });
  const filteredEnsemble = computed(() => {
    return searchEnsembleQuery.value ? ensembles.value.filter(e => e.name.toLowerCase().includes(searchEnsembleQuery.value.toLowerCase())) : ensembles.value;
  });
  function onSchoolSelect(event) {
    const selectedSchoolId = event.target.value;
    // console.log(selectedSchoolId);
    const selectedSchool = schools.value.find(school => school.id == selectedSchoolId);

    if (selectedSchool) {
      addSchool(selectedSchool);
    } else {
      console.error('Selected school not found.');
    }
  };

  async function addSchool(school) {
    // console.log(school);
    errors.value = errors.value.filter(error => error !== 'Select school/organization');
    form.value.school =  school.id;
    selectedSchool.value.id = school.id;
    selectedSchool.value.name = school.name;
    if(school.address.address1 != null && school.address.city != null && school.address.state != null && school.address.zip != null) {
      selectedSchool.value.address1 = school.address.address1;
      selectedSchool.value.address = `${school.address.city}, ${school.address.state} ${school.address.zip}`;
    }
    if (form.value.school) {
    await fetchEnsemblesForOrganization(form.value.school);
    }
    selectedEnsemble.value = '';
    form.value.ensemble =  '';
    searchSchoolQuery.value = '';
  };
  const clearSchool = () => {
    selectedSchool.value.id = '';
    selectedSchool.value.name = '';
    selectedSchool.value.address1 = '';
    selectedSchool.value.address = '';
    form.value.school =  '';
    selectedEnsemble.value= '';
    form.value.ensemble =  '';
    showSchool.value = false;
  };
  function onEnsembleSelect(event) {
    const selectedensembleId = event.target.value;
    // console.log(selectedensembleId);
    const selectedEnsemble = ensembles.value.find(ensembles => ensembles.id == selectedensembleId);

    if (selectedEnsemble) {
      addEnsemble(selectedEnsemble);
    } else {
      console.error('Selected school not found.');
    }
  };
  function addEnsemble (ensemble) {
    errors.value = errors.value.filter(error => error !== 'Select ensemble');
    showSchool.value = true;
    form.value.ensemble =  ensemble.id;
    selectedEnsemble.value = ensemble;
    searchEnsembleQuery.value = '';
  };
  async function clearEnsemble () {
    selectedEnsemble.value = '';
    form.value.ensemble =  '';
    showSchool.value = false;
    console.log(ensembles.value);
    // await fetchEnsemblesForOrganization(form.value.school);
  };
    

  const next = () => {
    if(validate()){
      return
    }
    emit('next', {
            step: 1,
            data: form.value
        });
  };

  const validate = () => {
    let flag = false;
    if (form.value.school == ''){
      const iserrorExists = errors.value.some((error) => error === 'Select school/organization');
      if (!iserrorExists){
        errors.value.push('Select school/organization');
      }
      flag = true;
    }
    if (form.value.ensemble == ''){
      const iserrorExists = errors.value.some((error) => error === 'Select ensemble');
      if (!iserrorExists){
        errors.value.push('Select ensemble');
      }
      flag = true;
    }
    return flag;
  };
  const emitClose = () => {
    emit('close');
  };
  </script>

  <style>
  /* Additional custom styling if needed */
  </style>
