<template>
  <div class="container mx-auto flex flex-col items-center pt-12 pb-12 px-4 md:px-0" style="background-color: #212c4f;">
    <div class="w-full md:w-2/3 lg:w-2/2 mx-auto bg-gray-800 p-8 rounded-lg shadow-md">
      <h1 class="text-white text-2xl mb-4">Agreement</h1>
      <label class="text-white text-left mb-4">Your account has been created. Please sign the agreement and submit your payment details to continue. If you need to resume later, log in using your credentials to pick up where you left off.</label>

      <!-- PDF.js Viewer -->
      <div id="pdf-viewer" ref="pdfViewer" class="w-full h-96 mb-4 mt-4 bg-white"></div>

      <!-- Checkbox Agreement Section -->
      <div class="mb-4">
        <label v-if="showError" class="text-red-500 text-left">Please check both boxes to proceed.</label>
        <div class="flex items-center mb-2">
          <input type="checkbox" v-model="checkboxesRead" :class="{'border-red-500': !checkboxesRead && showError}" class="mr-2" />
          <label class="text-white">I have read and understood the terms and conditions of the License Agreement</label>
        </div>
        <div class="flex items-center mb-4">
          <input type="checkbox" v-model="checkboxesConsent" :class="{'border-red-500': !checkboxesConsent && showError}" class="mr-2" />
          <label class="text-white">I consent to the 
            <a href="#" @click="openEsignDisclosure()" class="text-blue-500 hover:text-blue-700">ESIGN Act disclosure</a>
          </label>
        </div>
      </div>

      <!-- Signature Pad -->
      <div class="mb-4">
        <canvas ref="signaturePad" class="w-full h-40 bg-gray-100 rounded shadow-md"></canvas>
        <button @click="clearSignature" class="bg-red-500 text-white py-1 px-4 mt-2 rounded-md">Clear Signature</button>
      </div>

      <!-- Sign and Submit Button -->
      <button @click="signDocument" :disabled="!canSign || isLoading || signaturePad == null"
          :style="{ backgroundColor: !canSign || isLoading || signaturePad == null ? '#b2d8b5' : '#86bc25' }"
          class="bg-green-600 text-white py-3 px-6 rounded-md w-full">
        <div v-if="isLoading">
          <!-- Loader Icon or Animation -->
          <svg aria-hidden="true" class="inline w-5 h-5 text-gray-400 animate-spin dark:text-gray-500 fill-gray-900 dark:fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
        </div>
        <div v-else>Sign Document</div>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { jsPDF } from "jspdf";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { useStore } from 'vuex';
// GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';
GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.min.mjs";

export default {
  name: "FuneralAgreement",
  data() {
    return {
      userId: 0,
      checkboxesRead: false,
      checkboxesConsent: false,
      isLoading: false,
      signaturePad: null,
      document: '',
      showError: false,
      funeralForm : {}
    };
  },
  computed: {
    canSign() {
      return this.checkboxesRead && this.checkboxesConsent;
    },
  },
  mounted() {
    // GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();
    this.document = this.$store.getters.getDocument; // Retrieve the document from Vuex
    this.funeralForm = this.$store.getters.getFuneralForm;
    console.log('from funeral agreement' ,  this.funeralForm);
    console.log('Document prop:', this.document);
    if (this.document) {
      this.loadPDF();
      this.initSignaturePad();
    } else {
      console.error('Document prop is undefined.');
    }
    this.init()
  },
  methods: {
    openEsignDisclosure() {
      const path = '/ESIGN_DISCLOSURE.pdf';
      window.open(path, '_blank');
    },
    loadPDF() {
      const pdfViewer = this.$refs.pdfViewer;
      // Clear the previous canvas if it exists
      pdfViewer.innerHTML = ''; 

      // Create a new canvas element
      const pdfUrl = this.document; // Use the URL stored in Vuex

      getDocument(pdfUrl).promise.then(pdf => {
        // Loop through all the pages of the PDF
        for (let i = 1; i <= pdf.numPages; i++) {
          pdf.getPage(i).then(page => {
            const viewport = page.getViewport({ scale: 1.5 });
            const canvas = document.createElement('canvas');
            pdfViewer.appendChild(canvas);
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            
            const context = canvas.getContext('2d');
            page.render({ canvasContext: context, viewport: viewport });
          });
        }
      });
    },
    initSignaturePad() {
      const canvas = this.$refs.signaturePad;
      const ctx = canvas.getContext("2d");
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;

      let drawing = false;

      canvas.addEventListener("mousedown", () => {
        drawing = true;
        ctx.beginPath();
      });

      canvas.addEventListener("mousemove", (event) => {
        if (!drawing) return;
        ctx.lineWidth = 2;
        ctx.lineCap = "round";
        ctx.strokeStyle = "#000000";
        ctx.lineTo(event.offsetX, event.offsetY);
        ctx.stroke();
      });

      canvas.addEventListener("mouseup", () => {
        drawing = false;
      });

      this.signaturePad = ctx;
    },
    clearSignature() {
      const canvas = this.$refs.signaturePad;
      this.signaturePad.clearRect(0, 0, canvas.width, canvas.height);
    },
    init() {
      axios.post(axios.defaults.baseURL + 'account/lisynce/getuserid', {email: this.funeralForm.contactEmail})
      .then(response => {
        //download the pdf agreement
        this.userId = response.data.user_id;
      })
      .catch(error => {
        console.error('Error retrieving userId:', error);
      });
    },
    signDocument() {
      if (!this.canSign) {
        this.showError = true;
        return;
      }
      this.$updateLoading(1);
      if (this.canSign) {
        this.isLoading = true;
        this.showError = false;
        const canvas = this.$refs.signaturePad;
        const imgData = canvas.toDataURL("image/png");

        axios
          .post(
            `${axios.defaults.baseURL}account/lisynce/signFuneralFormAgreement`,
            {
              funeralForm: this.funeralForm,
              signatureImg: imgData,
              user_id: this.userId
            },
            { responseType: "blob" }
          )
          .then((response) => {
            // Download the signed PDF
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "signed_funeral_agreement.pdf");
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Update the funeral form in Vuex
            this.$store.dispatch("updateFuneralForm", this.funeralForm);

            // Update the registration status to 'AGREEMENT_SIGNED'
            return axios.post(
              `${axios.defaults.baseURL}account/lisynce/registration_status`,
              { user_id: this.userId, status: "AGREEMENT_SIGNED" }
            );
          })
          .then((regResponse) => {
            console.log("Registration status updated:", regResponse.data.success);

            // Redirect to the Credit Card Form page
            this.$router.push({ name: "CreditCardForm" });
            this.isLoading = false;
            this.$updateLoading(-1);
          })
          .catch((error) => {
            console.error("Error processing the agreement or updating status:", error);
            alert("An error occurred. Please try again.");
            this.isLoading = false;
            this.$updateLoading(-1);
          });
          this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 100vh;
  margin: 0 auto;
} */
#pdf-viewer {
  overflow-y: auto;
  max-width: 100%;
  height: 100vh; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

canvas {
  margin-bottom: 10px;
  border: 1px solid #ccc;
  max-width: 100%;
  height: auto;
}
</style>
  